<template>
<div>
  <div class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-white">
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column">
        <div class="row align-items-center mt-5">
          <div class="tab-content w-100 p-5">
            <div class="row align-items-center">

              <div class="col-md-12 py-10 pl-md-10">
                <div class="w-150px bg-primary my-5 p-1 order-1"></div>
                <h1 class="display-3 font-weight-boldest order-2">{{$t("COMMUNITY.CHIPARTECIPA")}}</h1>
                <div class="mt-10 lead order-3" v-html="getOrganization.data.block2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
import { mapGetters } from "vuex";
//import Button from './vue-bootstrap/Button.vue';

export default {
  name: "community",
  components: {
    //OrganizationMenu
    //Button
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, []);
     //this.getOrganization();
    // console.log(this.getOrganization);
  },
  created() {

  },
  methods: {
    getOrganization() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('organizations-expanded/1', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.organization = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            //console.log('errore');
            reject(0);
          });
      });
    }
  }
}
</script>

<style></style>
